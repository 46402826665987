<template>
  <a-card>
    <div class="talk_box">
      <div class="message_history">
        <div class="reception_message">
          <p>你将发送消息给{{ tagGroupList.length }}个群聊:</p>
          <a-tag color="blue" v-for="item in tagGroupList" :key="item.id">{{
            item.nick_name || "未命名"
          }}</a-tag>
        </div>
        <div class="message">
          <a-spin size="large" class="aspin_loading" :spinning='spainLoading'/>
          <div class="message-conent">
            <div v-for="(item, index) in messageList" :key="item.recordId">
              <p class="send_time">
                {{ item.date }}
              </p>
              <div class="send_conent">
                <span
                  ><a @click="resetSend(item.recordId, index)"
                    ><a-icon
                      type="sync"
                      v-if="item.checkStatus == 20 || item.checkStatus == 40"
                      style="
                        margin-right: 4px;
                        font-size: 16px;
                        color: #ccc;
                      " /></a
                  ><a-icon
                    type="check-circle"
                    v-if="item.checkStatus == 30"
                    style="color: green"
                  /><span
                    >{{ item.successRecord }}/{{ item.sendGroup }}</span
                  ></span
                >
                <div class="code">{{ item.message }}</div>
                <div class="triangle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="send_file">
        <a-icon type="picture" class="send_picture" @click="infoTips"/><a-icon
          type="video-camera" class="send_picture" @click="infoTips"
        />
      </div>
      <div class="textarea_box">
        <a-textarea
          v-model="messageText"
          :auto-size="{ minRows: 7, maxRows: 7 }"
          :bordered="false"
          :maxLength="350"
        />
        <div class="footer_btn">
          <span class="footer_tip"
            >今日还有<span>{{ sendCount }}</span
            >次额度</span
          ><a-button type="primary" @click="sendMessage" :loading="sendLoading"
            >发送</a-button
          >
        </div>
      </div>
      <!--  -->
    </div>
  </a-card>
</template>
<script>
export default {
  data() {
    return {
      messageText: "",
      windowId: this.$route.query.id,
      tagGroupList: [],
      sendCount: 0,
      sendLoading: false,
      messageList: [],
      spainLoading:false
    };
  },
  mounted() {
    this.getRecordTableList(1, 20);
    this.getSendCount();
    this.getSendMessageRecord(1, 20);
  },
  methods: {
    getRecordTableList(current, page_size) {
      let params = {
        window_id: this.windowId,
        page_num: current,
        page_size,
      };
      this.$api.core.GroupChat.editRecordGroup(params).then((res) => {
        if (res.code == 200) {
          this.tagGroupList = res.data.list;
        }
      });
    },
    // 剩余发送次数
    getSendCount() {
      this.$api.core.GroupChat.checkCount().then((res) => {
        if (res.code == 200) {
          this.sendCount = res.data;
        }
      });
    },
    // 点击发送
    sendMessage() {
      if(this.messageText.trim() == ''){
        this.$message.warn('请输入信息！')
        return
      }
      let payload = {
        window_id: this.windowId,
        contents: [
          {
            type: "TXT",
            content: this.messageText,
          },
        ],
      };
      this.sendLoading = true;
      this.$api.core.GroupChat.sendMessage(payload)
        .then((res) => {
          console.log("发送消息res", res);
          if (res.code == 200) {
            this.getSendCount();
            let stautsRecord = res.data.record_send_status[0];
            this.messageList.push({
              message: this.messageText,
              date: stautsRecord.mtime,
              checkStatus: stautsRecord.status,
              sendGroup: stautsRecord.contacts_count,
              successRecord: stautsRecord.finish_count,
              recordId: stautsRecord.id,
            });
            this.messageText = "";
          }else{
            this.$message.error(res.msg)
          }
        })
        .finally(() => {
          this.sendLoading = false;
        });
    },
    // 获取发送记录
    getSendMessageRecord(current, page_size) {
      let params = {
        page_num: current,
        page_size,
        window_id: this.windowId,
      };
      this.spainLoading =true
      this.$api.core.GroupChat.getSendRecord(params).then((res) => {
        console.log("群聊记录", res);
        if (res.code == 200) {
          let list = res.data.list;
          list.map((item) => {
            this.messageList.push({
              message: item.vk_msg_send_content.content,
              date: item.mtime,
              checkStatus: item.status,
              sendGroup: item.contacts_count,
              successRecord: item.finish_count,
              recordId: item.id,
            });
          });
        }
      }).finally(()=>{
        this.spainLoading = false
      });
    },
    resetSend(id, index) {
      console.log(1, id);
      let params = {
        record_id: id,
      };
      this.$api.core.GroupChat.againSend(params).then((res) => {
        let stautsRecord = res.data.record_send_status[0];
        this.messageList[index] = {
          message: this.messageText,
          date: stautsRecord.mtime,
          checkStatus: stautsRecord.status,
          sendGroup: stautsRecord.contacts_count,
          successRecord: stautsRecord.finish_count,
          recordId: stautsRecord.id,
        };
      });
    },
    infoTips(){
        this.$info({
        title: '功能规划中！',
        // content: "敬请期待",
        onOk() {},
      });

    }
  },
};
</script>
<style lang="scss" scoped>
.talk_box {
  width: 950px;
  height: 545px;
  border: 1px solid #ccc;
  margin: 0 auto;
  position: relative;
}
.reception_message {
  margin: 14px 32px;
  background: #eee;
  padding: 8px 12px;
}
.message {
  height: 240px;
  padding-bottom: 10px;
  overflow-y: auto;
  position: relative;
  &-conent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 26px;
  }
}
.send_time {
  margin: 8px 0;
  color: #c7c7c7;
  text-align: right;
}
.send_conent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .code {
    margin-left: 4px;
    padding: 10px;
    background: #c5e5fe;
    border-radius: 6px;
  }
  .triangle {
    position: absolute;
    right: -12px;
    top: 12px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-left: 6px solid #c5e5fe;
  }
}
.send_file {
  padding: 4px 18px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-size: 22px;
  .send_picture {
    margin-right: 12px;
    cursor: pointer;
  }
}
textarea,
textarea.ant-input:hover,
textarea:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.textarea_box {
  position: relative;
}
.footer_btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.footer_tip {
  font-size: 12px;
  color: #aaa;
  display: inline-block;
  padding-right: 12px;
  span {
    color: red;
    font-weight: 700;
  }
}
.aspin_loading{
  position: absolute;
  top:50%;
  left: 50%;
  margin:-16px -18px
}
</style>